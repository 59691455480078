import * as React from "react";
/* import { useTheme } from "@material-ui/styles";
 * import getTheme from "../themes/victoryTheme"; */
/* import { useTranslation } from "react-i18next"; */

/* const useState = React.useState; */

export default (props: any) => {
  /* const { t } = useTranslation(); */

  return (
    <div></div>
  );
};
